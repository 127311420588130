import { matchPath, useLocation } from "react-router";

export const useRoutes = () => {
  const location = useLocation();
  const categoryMatch = matchPath("/:category", location.pathname);
  const subcategoryMatch = matchPath(
    "/:category/:subcategory",
    location.pathname
  );
  const identifierMatch = matchPath(
    "/:category/:subcategory/:identifier",
    location.pathname
  );

  return {
    category: categoryMatch?.params?.category,
    subcategory: subcategoryMatch?.params?.subcategory,
    identifier: identifierMatch?.params?.identifier,
  };
};
