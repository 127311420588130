import { Fragment } from "react";
import classNames from "classnames";
import { Text } from "../Text/Text";

const ChatRow = ({
  messageItem,
  index,
}: {
  messageItem: any;
  index: number;
}) => {
  return (
    <div
      key={`${messageItem.type}-${index}`}
      className={classNames("chat-bot--body--message", {
        ["chat-bot--body--message--bot"]: messageItem.type === "bot",
        ["chat-bot--body--message--human"]: messageItem.type === "human",
      })}
    >
      {typeof messageItem.message === "string" ? (
        <Text font={"helvetica"} size={"SIZE20"} color={"black"}>
          {messageItem.message}
        </Text>
      ) : (
        <div>
          <Text font={"helvetica"} size={"SIZE20"} color={"black"}>
            {messageItem.message.title}
          </Text>
          <br />
          <p />
          <Text font={"helvetica"} size={"SIZE18"} color={"black"}>
            <Text font={"helvetica"} size={"SIZE14"} color={"black"}>
              {messageItem.message.content}
            </Text>
          </Text>
          <div />
          <p />
          {messageItem.message.sections &&
            messageItem.message.sections.map((section: any, idx: number) => {
              return (
                <div key={idx}>
                  <Text font={"helvetica"} size={"SIZE18"} color={"black"}>
                    <Text font={"helvetica"} size={"SIZE18"} color={"black"}>
                      {section.subtitle}
                    </Text>
                    <br />
                    {section.text.map((text: any, _idx: number) => {
                      return (
                        <Fragment key={_idx + text}>
                          <Text
                            font={"helvetica"}
                            size={"SIZE14"}
                            color={"black"}
                          >
                            {_idx + 1}. {text}
                          </Text>
                          <br />
                        </Fragment>
                      );
                    })}
                  </Text>
                  <br />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default ChatRow;
