import { FunctionComponent, PropsWithChildren } from "react";
import classNames from "classnames";

export const BackgroundBlocker: FunctionComponent<
  PropsWithChildren<{ overflow?: boolean; disabled?: boolean }>
> = ({ children, overflow, disabled }) => {
  const blockerClasses = classNames("background-blocker", {
    ["background-blocker--overflow"]: overflow,
  });

  return (
    <>{!disabled ? <div className={blockerClasses}>{children}</div> : null}</>
  );
};
