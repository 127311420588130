import { FunctionComponent, useEffect, useState } from "react";
import { ReactComponent as SwitchIcon } from "../../assets/icons/switcher.svg";
import classNames from "classnames";
import { ISwitch } from "./Switch.interfaces";

export const Switch: FunctionComponent<ISwitch> = ({
  value,
  onChange,
  disabled,
}) => {
  const [checked, setChecked] = useState(value || false);

  useEffect(() => {
    if (value !== undefined && value !== checked) {
      setChecked(value);
    }
  }, [value, checked]);

  function handleSwitch() {
    setChecked(!checked);
    onChange?.(!checked);
  }

  return (
    <button
      disabled={disabled}
      onClick={() => handleSwitch()}
      className={"switch"}
    >
      <div
        className={classNames("switch--layer", {
          ["switch--layer--checked"]: checked,
        })}
      >
        <SwitchIcon
          className={classNames("switch--tumbler", {
            ["switch--tumbler--checked"]: checked,
          })}
        />
      </div>
    </button>
  );
};
