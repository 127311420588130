import { FunctionComponent, memo, PropsWithChildren } from "react";
import { IText } from "./Text.interfaces";
import classNames from "classnames";

export const Text: FunctionComponent<PropsWithChildren<IText>> = ({
  children,
  shadow,
  underline,
  transform,
  size = "DEFAULT",
  whiteSpace,
  className,
  color = "white",
  font = "medium",
}) => {
  const fontSize = {
    SIZE10: "text--size10",
    SIZE12: "text--size12",
    SIZE14: "text--size14",
    DEFAULT: "text--default",
    SIZE18: "text--size18",
    SIZE20: "text--size20",
    SIZE22: "text--size22",
    SIZE24: "text--size24",
    SIZE30: "text--size30",
    SIZE36: "text--size36",
    SIZE40: "text--size40",
  };
  const fontColors = {
    white: "text--white",
    black: "text--black",
    pink: "text--pink",
    grey: "text--grey",
    green: "text--green",
    error: "text--red",
  };
  const fontFamily = {
    black: "text--gotham-black",
    medium: "text--gotham-medium",
    light: "text--gotham-light",
    book: "text--gotham-book",
    helvetica: "text--helvetica",
  };

  const computedClasses = classNames(
    "text",
    fontSize[size],
    fontColors[color],
    fontFamily[font],
    className,
    {
      "text--shadow": shadow,
      "text--underline": underline,
      "text--transform--uppercase": transform === "uppercase",
      "text--white-space": whiteSpace,
    }
  );

  return <span className={computedClasses}>{children}</span>;
};

export default memo(Text);
