import { FunctionComponent } from "react";
import { MainLayout } from "../../components/Layouts/MainLayout";

export const DatabasePage: FunctionComponent = () => {
  return (
    <MainLayout>
      <div className={"home-page"}>В разработке</div>
    </MainLayout>
  );
};
