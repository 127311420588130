import { RouteProps } from "react-router";

export enum pageNames {
  // eslint-disable-next-line
  TEST = "TEST",
  // eslint-disable-next-line
  HOME = "HOME",
  // eslint-disable-next-line
  DATABASE = "DATABASE",
  // eslint-disable-next-line
  BOOKMARKS = "BOOKMARKS",
  // eslint-disable-next-line
  ARCHIVE = "ARCHIVE",
  // eslint-disable-next-line
  KAVACAION = "KAVACAION",
  // eslint-disable-next-line
  SOFTWAREGUIDE = "SOFTWAREGUIDE",
  // eslint-disable-next-line
  JOIN = "JOIN",
  // eslint-disable-next-line
  PRODUCTS = "PRODUCTS",
  // eslint-disable-next-line
  ADDPRODUCTS = "ADDPRODUCTS",
}

type Routes = {
  title: string;
  isMenu?: boolean;
  disabled?: boolean;
};

export type IRoutesType = Routes & RouteProps;
