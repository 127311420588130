export const PRODUCTS_CONFIG = [
  {
    size: "half",
    bgColor: "grey",
    discount: null,
    group: "Cutting area",
  },
  {
    size: "half",
    bgColor: "grey",
    discount: 5,
    group: "",
  },
  {
    size: "full",
    bgColor: "white",
    discount: null,
    group: "",
  },
  {
    size: "full",
    bgColor: "white",
    discount: 15,
    group: "",
  },
  {
    size: "half",
    bgColor: "grey",
    discount: null,
    group: "Auto layouts",
  },
  {
    size: "half",
    bgColor: "grey",
    discount: 10,
    group: "",
  },
];

export const TEXT_MOCK = [
  "Click Ok to send a Trial Activation Code to your Email\nThe Trial Code is available only once",
  "Success",
  "Payment successful!\nThe Activation Code has been sent to your Email",
  "Error",
  "The Activation Code has been reserved.\nPlease, choose the payment method:",
  "The Trial Activation Code\nhas been sent to your email",
  "Something went wrong, try again later",
  "If when you try to purchase one of the credit packages, most likely the annual unlimited, you have the payment process stop and a message appears: \"We aren't able to process your payment using your PayPal account at this time. Please go back to the merchant and try using a different payment method\", but you have recently made PayPal purchases, perhaps even in our software, for a smaller amount, it is probable that there is a restriction on purchases over $1000 in your PayPal account. This account may have been automatically created during the attempted payment or previously set up by you. To lift this restriction and proceed with your purchases, you will need to confirm your bank card on PayPal. You must visit the official PayPal website www.paypal.com, log in using your email and password (if you don't remember it, follow the password recovery process), and then:",
  "When you confirm your debit or credit card, we'll make a small charge to your card. This charge and a 4-digit PayPal code will appear on your card statement. If you request the PayPal code more than once, you’ll find more than 1 charge on your card statement.\nYou need to use the latest code that you've requested.",
  "If you don’t have access to your card statements online, you can check for the code when you receive your next statement in the mail.",
  "If you can’t find your PayPal code, you can request a new one to be resent. A new PayPal code may be requested up to 3 times.",
  "Here's how to finish confirming your card with your 4-digit PayPal code from a web browser:",
  "After successful confirmation of your bank card, try to make the purchase again.",
  "If after confirming the bank card, still your purchase is rejected and the above-mentioned message appears, then in this case you need to perform additional confirmations in your PayPal account by following some of the mentioned procedures in this article on the official PayPal website",
];

export const SERVICE_MESSAGE =
  "" +
  "Our payment service is still temporarily unavailable due to technical maintenance.\n" +
  "\n" +
  "The estimated time of resuming the payment system operation is the end of February 2024.\n" +
  "\n" +
  "To get FREE BONUS CREDITS to continue using the software, please write to our e-mail:\n" +
  "support@nanoshine-group.com\n" +
  "\n" +
  "We apologize for any inconvenience caused.\n" +
  "\n" +
  "Thank you for your understanding.";

export const IS_SERVICE_MESSAGE = false;

export const MOCKED_PRODUCT = {
  name: "Test",
  description: "Test",
  extra_description: "Test",
  price: "150",
  trial: 0,
  order: 1,
  id: 1,
};
