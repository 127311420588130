import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import { Routes, Route, useLocation } from "react-router";
import { routeList } from "./routes";
import { IRoutesType } from "./utils";
import { useSearchParams } from "react-router-dom";
import { API_URL, HEADERS } from "./utils/constants";
import { BackgroundBlocker } from "./components/BackgroundBlocker/BackgroundBlocker";
import { Preloader } from "./components/Preloader/Preloader";
import { ReactComponent as ErrorIcon } from "./assets/icons/error_icon.svg";
import { Button } from "./components/Button/Button";
import { Dialog } from "./components/Dialog/Dialog";
import { ChatBot } from "./components/ChatBot/ChatBot";
import { notifyMessages } from "./utils/helper-functions";

export const AuthContext = createContext<
  [
    {
      authToken: string | null;
      userInfo: Record<string, any> | null;
      fetchUserData: ((token: string) => Promise<string>) | null;
    },
    Dispatch<SetStateAction<string>> | null,
  ]
>([{ authToken: null, userInfo: null, fetchUserData: null }, null]);

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [authToken, setAuthToken] = useState("");
  const [userInfo, setUserInfo] = useState<Record<string, any> | null>(null);
  const [settings, setSettings] = useState<Record<string, any> | null>(null);
  const [, setIsLoading] = useState(false);
  const account =
    searchParams.get("account") || process.env.REACT_APP_USER_ACCOUNT || null;
  const token =
    searchParams.get("token") || process.env.REACT_APP_USER_TOKEN || null;
  const code =
    searchParams.get("code") || process.env.REACT_APP_USER_CODE || null;
  const checkCode =
    searchParams.get("checkCode") ||
    process.env.REACT_APP_USER_CHECK_CODE ||
    null;
  const version =
    searchParams.get("version") || process.env.REACT_APP_USER_VERSION || null;
  const tenant =
    searchParams.get("tenant") || process.env.REACT_APP_USER_TENANT || null;
  const jwtToken = searchParams.get("jwt") || null;
  const currentRoute = useLocation();
  const [error, setError] = useState({
    isError: false,
    message: "",
  });

  const isAdminPage = useMemo(() => {
    return currentRoute.pathname === "/add-product";
  }, [currentRoute]);

  const renderRoute = useCallback(
    ({ path, children = [], ...rest }: IRoutesType) => (
      <Route key={path} path={path} {...rest} />
    ),
    []
  );

  useEffect(() => {
    if (jwtToken) {
      setAuthToken(jwtToken);
      searchParams.delete("jwt");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line
  }, [jwtToken]);

  useEffect(() => {
    const FetchData = async () => {
      const userUrl = API_URL + "/api/login";
      const userData = {
        account,
        token,
        checkCode,
        code,
        version,
        tenant,
      };
      return await fetch(userUrl, {
        headers: HEADERS,
        method: "POST",
        body: JSON.stringify(userData),
      });
    };

    const FetchSettings = async (token: string) => {
      return await fetch(API_URL + "/api/settings", {
        headers: {
          ...HEADERS,
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      });
    };

    if (
      token &&
      checkCode &&
      code &&
      account &&
      (!authToken || authToken === "") &&
      !isAdminPage
    ) {
      FetchData()
        .then((response) => response.json())
        .then(async (res) => {
          if (!res.errors && res.data && setAuthToken) {
            setAuthToken(res?.data?.access_token || null);
            await fetchUserData(res?.data?.access_token);
          } else {
            setError({
              isError: true,
              message:
                res.errors?.message ||
                "Authentication error, please try again later",
            });
          }
          setIsLoading(false);
        });
    }

    if (authToken) {
      FetchSettings(authToken)
        .then((response) => response.json())
        .then((res) => {
          if (!res.errors) {
            setSettings(res.data);
          }
          if (res.status === "failed" || res.errors) {
            notifyMessages(res.errors);
          }
        });
    }
    // eslint-disable-next-line
  }, [token, checkCode, code, account, authToken]);

  async function fetchUserData(token: string): Promise<string> {
    const FetchUserData = async (token: string) => {
      return await fetch(API_URL + "/api/users/current", {
        headers: {
          ...HEADERS,
          Authorization: `Bearer ${token}`,
        },
      });
    };
    return new Promise<string>((resolve, reject) => {
      FetchUserData(token)
        .then((response) => response.json())
        .then((res) => {
          if (!res.errors) {
            setUserInfo(res);
            resolve("success");
          } else {
            reject("error");
            if (res.status === "failed" || res.errors) {
              notifyMessages(res.errors);
            }
          }
        });
    });
  }

  return (
    <AuthContext.Provider
      value={[{ authToken, userInfo, fetchUserData }, setAuthToken]}
    >
      <div className={"root"}>
        <Routes>{routeList.map(renderRoute)}</Routes>
      </div>
      {false ? (
        <BackgroundBlocker>
          <Preloader />
        </BackgroundBlocker>
      ) : null}
      <Dialog
        isOpen={error.isError}
        icon={<ErrorIcon />}
        message={error.message}
        renderControls={() => (
          <div className={"root--file-cancel"}>
            <Button
              onClick={() => setError({ isError: false, message: "" })}
              size={"small"}
              color={"grey"}
            >
              {"OK"}
            </Button>
          </div>
        )}
      />
      {settings?.is_chat_available ? <ChatBot /> : null}
      <ToastContainer />
    </AuthContext.Provider>
  );
}

export default App;
