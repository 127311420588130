import { toast, ToastOptions } from "react-toastify";

type NotifyOptionTypes = {
  text: any;
  variant?: "success" | "info" | "error";
  opts?: ToastOptions;
};

export function notify({ text, variant = "info", opts }: NotifyOptionTypes) {
  const defOptions = {
    position: "top-right",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  };
  const options = { ...defOptions, ...opts };

  // @ts-ignore
  return toast[variant](text, options);
}

export function formatError(errorObj: object | string) {
  const textValues = Object.values(errorObj);
  const textKeys = Object.keys(errorObj);
  let errorsString = "";
  if (textValues.length === 0) return "";

  textValues.forEach((err, index) => {
    if (typeof err === "string") {
      errorsString += `${capitalize(textKeys[index])}: ${err}</br>`;
    } else {
      if (Array.isArray(err)) {
        err.map((e: any) => {
          errorsString += `${capitalize(textKeys[index])}: ${e}</br>`;
        });
      }
    }
  });

  return errorsString.trim();
}

export function notifyMessages(
  errorObj: object | string,
  variant: "success" | "info" | "error" = "error"
) {
  const errorText = formatError(errorObj);
  if (typeof errorObj === "string") {
    notify({ text: errorObj, variant });
  } else if (typeof errorObj === "undefined" || errorObj === undefined) {
    return "";
  } else {
    notify({
      text: <p dangerouslySetInnerHTML={{ __html: errorText }} />,
      variant,
    });
  }
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
