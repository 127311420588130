import { FunctionComponent, useMemo } from "react";
import { ISideMenu } from "./SideMenu.interfaces";
import { Link } from "react-router-dom";
import { useRoutes } from "../../hooks/useRoutes.hook";
import classNames from "classnames";

export const SideMenu: FunctionComponent<ISideMenu> = ({ items }) => {
  const { category } = useRoutes();

  const selectedRouteName = useMemo(() => {
    if (category) return category;
    return "";
  }, [category]);

  function isSelected(path: string) {
    return selectedRouteName === path.replace("/", "");
  }

  return (
    <div className={"side-menu"}>
      {items.map((menuItem, menuIndex) => {
        return (
          <Link
            className={classNames("side-menu-link", {
              "side-menu-selected": isSelected(menuItem.path),
            })}
            key={`${menuItem.title}-${menuIndex}`}
            to={menuItem.path}
          >
            {menuItem.title}
          </Link>
        );
      })}
    </div>
  );
};
