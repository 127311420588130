export const HOME_PAGE_CARDS = [
  {
    side: "left-bottom",
    items: [
      {
        title: "KAVACA ION",
        path: "/kavaca-ion",
        img: "/assets/image/MAIN_PAGE_ICONS-04.jpg",
      },
    ],
  },
  {
    side: "left-bottom",
    items: [
      {
        title: "SOFTWARE\nGUIDE",
        path: "/software-guide",
        img: "/assets/image/MAIN_PAGE_ICONS-01.jpg",
      },
    ],
  },
  {
    side: "left-bottom",
    items: [
      {
        title: "JOIN\nOUR NETWORK",
        path: "/join-us",
        img: "/assets/image/MAIN_PAGE_ICONS-03.jpg",
      },
    ],
  },
  {
    side: "left-bottom",
    items: [
      {
        title: "PRE-CUT FILM\nINSTALLATION\nINSTRUCTIONS",
        path: "",
        img: "/assets/image/MAIN_PAGE_ICONS-02.jpg",
      },
      {
        title: "BUY CREDITS",
        path: "/buy-credits",
        img: "",
        bg: "pink",
      },
    ],
  },
];

export const SEARCH_PARAMS =
  "?account=afbf7b6f-a2cb-47ff-9225-44352d9a0855&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImFmYmY3YjZmLWEyY2ItNDdmZi05MjI1LTQ0MzUyZDlhMDg1NSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJ0ZXN0MSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IiIsImdyb3VwIjoiIiwib3duZXIiOiIxMTJhZmUwMS1mOGJjLTRkZjItOWY1Yy05ZWYwNmE4YjRhZjMiLCJwYXJlbnQiOiIiLCJ0ZW5hbnQiOiJiZTVmNWQzMi01OGRlLTQyYWQtYmEzOS04MDlhYTMxZTFiOGYiLCJzb3VyY2UiOiJERUZBVUxUIiwianRpIjoiMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAiLCJzdWIiOiJ0ZXN0MSIsImlzcyI6IjEwMDAwMCIsImF1ZCI6IjEwMDAwMCJ9.7Fx93fR6KMg8Wmrf_whAnXXu-xzVoJSC6ZVtKUNWN-8&code=91000&version=1.0.0.0&checkCode=30d10b93bedc30ad79829aec72b33488&tenant=be5f5d32-58de-42ad-ba39-809aa31e1b8f";
